export const FADE_DURATION = 250
export const MAX_USER_AGE = 80

export const STEP = {
  ASK_GEOLOCATION: 'ask-geolocation',
  FILL_FORM: 'fill-form',
  SHOW_SUMMARY: 'show-summary',
  COMPLETE: 'complete',
}

export const ERROR_MESSAGE = {
  birthDateErr: '* Please Select',
  citizenIdErr: '* Invalid CitizenId',
  termsErr: '* Please accept our Terms of Service to Submit',
}
