import styled from 'styled-components'
import { Segment } from 'semantic-ui-react'

export const SubmitButton = styled.button`
  color: #fff;
  height: 40px;
  margin: auto;
  display: block;
  font-size: 16px;
  width: 220px;
  border-radius: 28px;
  background-color: #2fc2be;
  border: 1px solid #2fc2be;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  :hover {
    cursor: pointer;
  }

  :focus {
    outline: none;
    box-shadow: 0px 1px 4pt 0pt #6da3f8;
  }
`

export const SummaryInfoStyle = styled.div`
  max-width: 700px;
  min-height: 736px;
  margin: auto;
  margin-top: 60px;
  background: #fff;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  text-align: left;
  padding: 20px 4%;
  a {
    padding: 0 5px;
  }
  input[type="text"],
  input[type="email"] {
    height: 35px;
    border: 1px solid #bdbdbd;
    padding: 10px;
    font-size: 14px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }
  .information-form {
    max-width: 405px;
    display: block;
    margin: auto;
  }
  .flex {
    display: flex;
    flex-wrap: wrap;
  }
  .input-title {
    margin: 5px;
    font-size: 16px;
  }
  .form-row {
    margin: 24px 0;
  }
  .form-header {
    font-size: 24px;
  }
  .col-2 {
    width: 100%;
    max-width: 186px;
    margin-bottom: 10px;
    margin-right: 16px;
  }
  .col-3 {
    width: 100%;
    max-width: 380px;
    margin-bottom: 10px;
    margin-right: 16px;
  }
  .summary {
    max-width: 500px;
    padding: 25px 0;
  }
  .summary-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid #ededed;
  }
  .summary-col {
    width: 245px;
    height: 55px;
    padding: 18px 0;
    text-align: left;
    font-size: 16px;
    font-weight: lighter;
  }
  .summary-col-name {
    width: 245px;
    height: 55px;
    padding: 18px 0;
    text-align: left;
    font-size: 16px;
  }
  textarea {
    width: 100%;
    height: 88px;
    margin: 18px 0;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #dadada;
    font-size: 16px;
    padding: 8px;
    font-weight: 300;
    box-sizing: border-box;
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.03);
  }
  .summary-bt-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .summary-next-bt {
    margin: 0;
  }
  .summary-back-bt {
    margin: 0 20px 10px 0;
    background: #fff;
    color: #2fc2be;
  }
  .error-text {
    padding: 0 20px;
    text-align: left;
    color: red;
  }
  .term-text {
    font-size: 13px;
  }
  .term-checkbox {
    margin: 0 5px;
  }
`

export const SegmentForm = styled(Segment)`
  &&& {
    border: none;
    box-shadow: none;
  }
`
