import React from 'react'

import Container from './style'

const PrivacyPolicy = () => (
  <Container>
    <iframe
      className="privacy-policy-iframe"
      src="https://user-contents.ooca.co/statics/privacy-policies/en.html"
      title="privacy-policies"
    />
  </Container>
)

export default PrivacyPolicy
