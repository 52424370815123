import React from 'react'
import {
  Transition,
  Loader,
  Segment,
  TransitionablePortal,
} from 'semantic-ui-react'
import { OpdFormStyle, SubmitButton } from './style'
import { STEP, FADE_DURATION } from '../configForm'
import text from './text'

const errorSignStyle = {
  background: '#fd5353',
  color: '#fff',
  right: '20%',
  position: 'fixed',
  top: '10%',
  zIndex: 1000,
}

const SummaryInfo = ({
  isShow,
  loading,
  showError,
  errorMessage,
  information: {
    hn,
    firstname,
    lastname,
    email,
    gender,
    phone,
    birthdate,
    note,
  },
  ...props
}) => {
  const renderCol = (name, value) => (
    <div className="summary-row">
      <div className="summary-col-name">{name}</div>
      <div className="summary-col">
        {' '}
        {value}
      </div>
    </div>
  )

  return (
    <Segment>
      <Loader active={loading} />
      <Transition.Group animation="fade" duration={FADE_DURATION}>
        {isShow && (
          <OpdFormStyle>
            <div className="form-header">
              {' '}
              {text.summaryInformation}
              {' '}
            </div>
            <div className="summary">
              {renderCol(text.hospitalityNumber, hn)}
              {renderCol(text.firstname, firstname)}
              {renderCol(text.lastname, lastname)}
              {renderCol(text.gender, gender)}
              {renderCol(text.dateOfBirth, birthdate)}
              {renderCol(text.phone, phone)}
              {renderCol(text.email, email)}
            </div>
            <div className="form-header">
              {' '}
              {text.note}
              {' '}
            </div>
            <textarea
              name="note"
              onChange={props.handleChangeInfo}
              defaultValue={note}
            />
            <div className="summary-bt-row ">
              <SubmitButton
                className="summary-back-bt"
                onClick={() => props.moveStepTo(STEP.FILL_FORM)}
                type="button"
              >
                back
              </SubmitButton>
              <SubmitButton
                className="summary-next-bt"
                onClick={props.onSubmitForm}
                type="button"
              >
                {text.register}
              </SubmitButton>
            </div>
          </OpdFormStyle>
        )}
      </Transition.Group>
      <TransitionablePortal onClose={props.handleCloseError} open={showError}>
        <Segment style={errorSignStyle}>
          <h3>
            {text.sorry}
            {' '}
          </h3>
          <p>{errorMessage}</p>
        </Segment>
      </TransitionablePortal>
    </Segment>
  )
}

export default SummaryInfo
