import React from 'react'
import ReactMarkdown from 'react-markdown'
import md from './TermsAndCondition.md'

import { Container } from './style'

const TermsAndCondition = () => (
  <Container>
    <ReactMarkdown
      source={md}
    />
  </Container>
)

export default TermsAndCondition
