import React from 'react'
import { Transition } from 'semantic-ui-react'
import { CompleteForm, SubmitButton } from './style'
import checkedIcon from '../../../assets/images/checked-icon.png'
import text from './text'
import { STEP, FADE_DURATION } from '../configForm'

const CompletePostForm = ({ isShow, moveStepTo, completeMessage }) => (
  <Transition.Group animation="fade" duration={FADE_DURATION}>
    {isShow && (
      <CompleteForm>
        <img src={checkedIcon} className="icon-img" alt="checked-icon" />
        <div className="header-text">{text.registerCompleted}</div>
        <div className="sub-header-text">{completeMessage}</div>
        <div className="bt-row ">
          <SubmitButton
            className="next-bt"
            onClick={() => moveStepTo(STEP.FILL_FORM)}
            type="button"
          >
            {text.registerAgain}
          </SubmitButton>
        </div>
      </CompleteForm>
    )}
  </Transition.Group>
)
export default CompletePostForm
