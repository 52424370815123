import React from 'react'
import { Transition, Icon } from 'semantic-ui-react'
import { CompleteForm, SubmitButton } from './style'
import text from './text'
import { FADE_DURATION } from '../configEmbassy'

const AskGeolocation = ({ isShow }) => (
  <Transition.Group animation="fade" duration={FADE_DURATION}>
    {isShow && (
    <CompleteForm>
      <Icon
        className="icon-img"
        circular
        inverted
        color="blue"
        name="map marker alternate"
      />
      <div className="header-text">{text.pleaseAllowLocation}</div>
      <div className="bt-row ">
        <SubmitButton
          className="next-bt"
          onClick={() => window.location.reload()}
          type="button"
        >
          {text.reload}
        </SubmitButton>
      </div>
    </CompleteForm>
    )}
  </Transition.Group>
)

export default AskGeolocation
