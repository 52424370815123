export const hospitalURL = (route, param) => {
  const baseURL = process.env.REACT_APP_BASE_URL
  const url = {
    hospitals: `${baseURL}/hospitals`,
    embassy: `${baseURL}/embassy`,
    getToken: `${baseURL}/get-token`,
  }

  return url[route] || baseURL
}

export default hospitalURL
