import styled from 'styled-components'

export const Container = styled.div`
  max-width: 700px;
  min-height: 736px;
  margin: auto;
  margin-top: 60px;
  background: #fff;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  text-align: left;
  padding: 20px 4%;
`
