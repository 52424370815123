import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './App.css'
import Navbar from './component/navbar'
import OpdForm from './pages/opdForm'
import EmbassyForm from './pages/embassyForm'
import 'semantic-ui-css/semantic.min.css'
import TermsAndCondition from './pages/termsAndCondition'
import PrivacyPolicy from './pages/privacyPolicy'

const App = () => (
  <Router>
    <Navbar />
    <Switch>
      <Route exact path="/" component={OpdForm} />
      <Route path="/hospital" component={OpdForm} />
      <Route path="/embassy" component={EmbassyForm} />
      <Route path="/terms-and-condition" component={TermsAndCondition} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
    </Switch>
  </Router>
)

export default App
