import styled from 'styled-components'

const NavbarStyle = styled.div`
  width: 100%;
  height: 70px;
  background: #0055b8;
  box-shadow: 20px 8px 8px rgba(0, 0, 0, 0.1);
`

export default NavbarStyle
