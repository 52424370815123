import React from 'react'
import { Transition } from 'semantic-ui-react'
import {
  EmbassyFormStyle,
  CustomRadioButton,
  SubmitButton,
  BirthDateInput,
  InputPhoneCustomStyle,
} from './style'
import { FADE_DURATION } from '../configEmbassy'
import { DATE_OPTIONS } from '../../../utils/dateOptions'

import text from './text'
import 'react-phone-number-input/style.css'


const OpdFormInput = ({
  isShow,
  birthDateErr,
  day,
  month,
  year,
  termsErr,
  citizenIdErr,
  phoneErr,
  information: {
    firstname,
    lastname,
    email,
    gender,
    phone,
    citizenId,
    acceptTerms,
  },
  ...props
}) => {
  const selectionDate = (name, value, options) => (
    <BirthDateInput
      onChange={props.handleChangeBirthDate}
      placeholder={name}
      options={options}
      value={value}
      name={name}
      search
      selection
      fluid
    />
  )

  return (
    <Transition.Group animation="fade" duration={FADE_DURATION}>
      {isShow && (
        <EmbassyFormStyle>
          <div className="information-form">
            <div className="form-header">
              {text.personalInformation}
            </div>
            <form onSubmit={props.completeForm}>
              <div className="form-row flex">
                <div>
                  <div className="input-title">
                    {text.firstname}
                  </div>
                  <input
                    name="firstname"
                    value={firstname}
                    onChange={props.handleChangeInfo}
                    className="col-2"
                    type="text"
                    placeholder="Jane"
                    required
                  />
                </div>
                <div>
                  <div className="input-title">
                    {text.lastname}
                  </div>
                  <input
                    name="lastname"
                    value={lastname}
                    onChange={props.handleChangeInfo}
                    className="col-2"
                    type="text"
                    placeholder="Doe"
                    required
                  />
                </div>
              </div>

              <div className="input-title">
                {text.gender}
              </div>
              <CustomRadioButton>
                <div>
                  <input
                    type="radio"
                    id="radio-male"
                    name="gender"
                    value="male"
                    onChange={props.handleChangeInfo}
                    checked={gender === 'male'}
                  />
                  <label className="col-2" htmlFor="radio-male">
                    {text.male}
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="radio-female"
                    name="gender"
                    value="female"
                    onChange={props.handleChangeInfo}
                    checked={gender === 'female'}
                  />
                  <label className="col-2" htmlFor="radio-female">
                    {text.female}
                  </label>
                </div>
              </CustomRadioButton>

              <div className="form-row">
                <div className="input-title">
                  {text.dateOfBirth}
                  {birthDateErr && <span className="error-text">{birthDateErr}</span>}
                </div>
                <div className="flex">
                  {selectionDate('day', day, DATE_OPTIONS.DAYS)}
                  {selectionDate('month', month, DATE_OPTIONS.MONTHS)}
                  {selectionDate('year', year, DATE_OPTIONS.YEARS)}
                </div>
              </div>
              <div className="form-row">
                <div className="input-title">
                  {text.phone}
                  {phoneErr && <span className="error-text">{phoneErr}</span>}
                </div>
                <InputPhoneCustomStyle
                  placeholder="081 1234 564"
                  value={phone}
                  onChange={props.handlePhone}
                  country="TH"
                  limitMaxLength
                />
              </div>

              <div className="form-row">
                <div className="input-title">
                  {text.citizenId}
                  <span className="error-text">
                    {citizenIdErr}
                  </span>
                </div>
                <input
                  name="citizenId"
                  value={citizenId}
                  onChange={props.handleChangeInfo}
                  className="col-3"
                  type="text"
                  placeholder="12345678910121"
                  minLength="13"
                  maxLength="13"
                  required
                />
              </div>

              <div className="form-row">
                <div className="input-title">
                  {text.email}
                </div>
                <input
                  name="email"
                  value={email}
                  onChange={props.handleChangeInfo}
                  className="col-3"
                  type="email"
                  placeholder="jane@mockup.com"
                  required
                />
              </div>

              <div className="form-row term-text">
                <input
                  type="checkbox"
                  className="term-checkbox"
                  name="acceptTerms"
                  defaultChecked={acceptTerms}
                  onClick={props.handleChangeCheckbox}
                />
                <label>
                  {text.term.IAgreeToThe}
                  <a href="/terms-and-condition" target="blank">
                    {text.term.termAndConditions}
                  </a>
                  {text.and}
                  <a href="/privacy-policy" target="blank">
                    {text.term.privacyPolicy}
                  </a>
                </label>
                {termsErr && <div className="error-text">{termsErr}</div>}
              </div>

              <SubmitButton type="submit">{text.register}</SubmitButton>
            </form>
          </div>
        </EmbassyFormStyle>
      )}
    </Transition.Group>
  )
}

export default OpdFormInput
