import React, { Component } from 'react'
import NavbarStyled from './style'
import oocaLogo from '../../assets/images/oocaLogo.png'

class Navbar extends Component {
  state = {
    step: 1,
  };

  render() {
    return (
      <NavbarStyled>
        <img src={oocaLogo} alt="logo" />
      </NavbarStyled>
    )
  }
}

export default Navbar
