export const FADE_DURATION = 250
export const ERROR_MESSAGE = {
  birthDateErr: '* Please Select',
  phoneErr: '* Please fill this form',
  termsErr: '* Please accept our Terms of Service to Submit',
}

export const STEP = {
  FILL_FORM: 'fill-form',
  SHOW_SUMMARY: 'show-summary',
  COMPLETE: 'complete',
}
