import React, { Component } from 'react'
import * as R from 'ramda'
import EmbassyFormInput from './embassyFormInput/EmbassyFormInput'
import SummaryInfo from './summaryInfo/SummaryInfo'
import CompletePostForm from './completePostFrom/CompletePostForm'
import AskGeolocation from './askGeolocation'
import { FADE_DURATION, STEP, ERROR_MESSAGE } from './configEmbassy'

const INITIAL_STATE = {
  step: STEP.ASK_GEOLOCATION,
  isShow: true,
  loading: false,
  day: null,
  month: null,
  year: null,
  birthDateErr: null,
  termsErr: null,
  citizenIdErr: null,
  showError: false,
  errorMessage: null,
  completeMessage: null,
  information: {
    hn: '',
    firstname: '',
    lastname: '',
    email: '',
    gender: 'male',
    phone: '',
    note: '',
    birthdate: '',
    citizenId: '',
    acceptTerms: false,
    countryCode: null,
    latitude: null,
    longitude: null,
  },
}
class EmbassyForm extends Component {
  state = INITIAL_STATE;

  componentDidMount() {
    this.setGeoLocation()

    document.title = process.env.REACT_APP_HOSPITAL_NAME || 'ooca'
  }

  clearState = () => {
    const { countryCode, latitude, longitude } = this.state.information
    const information = {
      ...INITIAL_STATE.information, countryCode, latitude, longitude,
    }
    this.setState({ ...INITIAL_STATE, information })
  };

  setGeoLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.geolocationToState, (err) => console.error(err))
    } else {
      console.error('Geolocation is not supported by this browser.')
    }
  };

  geolocationToState = async (position) => {
    console.info(position)
    const { latitude, longitude } = position.coords
    this.setState({
      information: {
        ...this.state.information,
        latitude,
        longitude,
      },
    })
    this.moveStepTo(STEP.FILL_FORM)
  };

  validCitizenId = () => {
    const { citizenId } = this.state.information
    const lastDigit = parseInt(citizenId.charAt(12))
    let sum = 0
    let checksum = 0
    for (let i = 0; i < 12; i++) {
      sum += parseInt(citizenId.charAt(i)) * (13 - i)
    }
    checksum = (11 - (sum % 11)) % 10
    return checksum === lastDigit
  };

  toggleValidate = async (name, condition) => {
    let value = null
    if (!condition) {
      value = ERROR_MESSAGE[name]
    }
    this.setState({ [name]: value })
  };

  completeForm = async (e) => {
    e.preventDefault()
    let { day, month, year } = this.state
    const { acceptTerms } = this.state.information
    await this.toggleValidate('birthDateErr', day && month && year)
    await this.toggleValidate('citizenIdErr', this.validCitizenId())
    await this.toggleValidate('termsErr', acceptTerms)

    const { birthDateErr, citizenIdErr, termsErr } = this.state

    if (!birthDateErr && !citizenIdErr && !termsErr) {
      day = String(day).padStart(2, '0')
      month = String(month).padStart(2, '0')
      const information = {
        ...this.state.information,
        birthdate: `${day}-${month}-${year}`,
      }
      this.setState({ isShow: false })
      setTimeout(() => {
        this.setState({
          step: STEP.SHOW_SUMMARY,
          information,
          isShow: true,
          birthDateErr: null,
          termsErr: null,
          citizenIdErr: null,
        })
      }, FADE_DURATION)
    }
  };

  handleChangeInfo = (e) => {
    const information = {
      ...this.state.information,
      [e.target.name]: e.target.value,
    }
    this.setState({ information })
    this.validCitizenId()
  };

  handlePhone = (value) => {
    this.setState({ information: { ...this.state.information, phone: value } })
  };

  handleChangeCheckbox = (e) => {
    const information = {
      ...this.state.information,
      [e.target.name]: e.target.checked,
    }
    this.setState({ information })
  };

  handleChangeBirthDate = async (e, target) => {
    this.setState({ [target.name]: target.value })
    const { month, year, day } = this.state
    const maxDayOfMount = new Date(year, month, 0).getDate()
    if (day > maxDayOfMount) {
      this.setState({
        day: maxDayOfMount,
      })
    }
  };

  handleCloseError = () => {
    this.setState({ showError: false })
  };

  moveStepTo = (step) => {
    this.setState({ isShow: false, showError: false })
    setTimeout(() => {
      this.setState({ step, isShow: true })
    }, FADE_DURATION)
  };

  prepareParamAfterSubmit = async () => {
    const { information, year } = this.state
    const { latitude, longitude } = information
    let {
      month,
      day,
    } = this.state

    const countryCode = await this.props.getCountryFromGoogle(
      latitude,
      longitude,
    )

    const convertBEtoAD = process.env.REACT_APP_YEAR_TYPE === 'BE' ? -543 : 0
    day = String(day).padStart(2, '0')
    month = String(month).padStart(2, '0')

    return {
      ...information,
      countryCode,
      birthdate: `${year + convertBEtoAD}-${month}-${day}`,
    }
  };

  onSubmitForm = async () => {
    this.setState({ loading: true })
    try {
      const param = await this.prepareParamAfterSubmit()
      const result = await this.props.createEmbassyUser(param)
      this.clearState()
      this.setState({ completeMessage: result.data.message })
      this.moveStepTo(STEP.COMPLETE)
    } catch (e) {
      console.error(e.response)
      const errorMessage = R.pathOr('Internal server has failed', ['response', 'data', 'message'], e)

      this.setState({
        errorMessage,
        showError: true,
        loading: false,
      })
    }
  };

  render() {
    const { step } = this.state
    switch (step) {
      case STEP.ASK_GEOLOCATION:
        return (
          <AskGeolocation
            isShow={this.state.isShow}
            moveStepTo={this.moveStepTo}
            FADE_DURATION={FADE_DURATION}
          />
        )

      case STEP.FILL_FORM:
        return (
          <EmbassyFormInput
            {...this.state}
            handlePhone={this.handlePhone}
            handleChangeInfo={this.handleChangeInfo}
            handleChangeBirthDate={this.handleChangeBirthDate}
            handleChangeCheckbox={this.handleChangeCheckbox}
            completeForm={this.completeForm}
          />
        )


      case STEP.SHOW_SUMMARY:
        return (
          <SummaryInfo
            {...this.state}
            handleChangeInfo={this.handleChangeInfo}
            onSubmitForm={this.onSubmitForm}
            moveStepTo={this.moveStepTo}
            handleCloseError={this.handleCloseError}
          />
        )


      default:
        return (
          <CompletePostForm
            isShow={this.state.isShow}
            completeMessage={this.state.completeMessage}
            moveStepTo={this.moveStepTo}
          />
        )
    }
  }
}

export default EmbassyForm
