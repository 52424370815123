import React from 'react'
import { Transition } from 'semantic-ui-react'
import {
  OpdFormStyle,
  CustomRadioButton,
  SubmitButton,
  BirthDateInput,
  InputPhoneCustomStyle,
} from './style'
import { FADE_DURATION } from '../configForm'
import { DATE_OPTIONS } from '../../../utils/dateOptions'

import text from './text'

import 'react-phone-number-input/style.css'

const OpdFormInput = ({
  isShow,
  birthDateErr,
  phoneErr,
  day,
  month,
  year,
  termsErr,
  information: {
    hn,
    firstname,
    lastname,
    email,
    gender,
    phone,
    acceptTerms,
  },
  ...props
}) => {
  console.log('env -> ', process.env)
  const selectionDate = (name, value, options) => (
    <BirthDateInput
      onChange={props.handleChangeBirthDate}
      placeholder={name}
      options={options}
      value={value}
      name={name}
      search
      selection
      fluid
    />
  )
  return (
    <Transition.Group animation="fade" duration={FADE_DURATION}>
      {isShow
        && (
          <OpdFormStyle>
            <div className="information-form">
              <div className="form-header">
                {text.personalInformation}
              </div>
              <form onSubmit={props.completeForm}>
                {JSON.parse(process.env.REACT_APP_HN_SHOW || 'false') === true && (
                  <div className="form-row">
                    <div className="input-title">
                      {text.hospitalityNumber}
                    </div>
                    <input
                      name="hn"
                      onChange={props.handleChangeInfo}
                      className="col-3"
                      value={hn}
                      type="text"
                      placeholder="123456789"
                      required
                    />
                  </div>
                )}
                <div className="form-row flex">
                  <div>
                    <div className="input-title">
                      {text.firstname}
                    </div>
                    <input
                      name="firstname"
                      value={firstname}
                      onChange={props.handleChangeInfo}
                      className="col-2"
                      type="text"
                      placeholder="Jane"
                      required
                    />
                  </div>
                  <div>
                    <div className="input-title">
                      {text.lastname}
                    </div>
                    <input
                      name="lastname"
                      value={lastname}
                      onChange={props.handleChangeInfo}
                      className="col-2"
                      type="text"
                      placeholder="Doe"
                      required
                    />
                  </div>
                </div>

                <div className="input-title">
                  {text.gender}
                </div>
                <CustomRadioButton>
                  <div>
                    <input
                      type="radio"
                      id="radio-male"
                      name="gender"
                      value="male"
                      onChange={props.handleChangeInfo}
                      checked={gender === 'male'}
                    />
                    <label htmlFor="radio-male">{text.male}</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="radio-female"
                      name="gender"
                      value="female"
                      onChange={props.handleChangeInfo}
                      checked={gender === 'female'}
                    />
                    <label htmlFor="radio-female">{text.female}</label>
                  </div>
                </CustomRadioButton>

                <div className="form-row">
                  <div className="input-title">
                    {text.dateOfBirth}
                    {birthDateErr && <span className="error-text">{birthDateErr}</span>}
                  </div>
                  <div className="flex">
                    {selectionDate('day', day, DATE_OPTIONS.DAYS)}
                    {selectionDate('month', month, DATE_OPTIONS.MONTHS)}
                    {selectionDate('year', year, DATE_OPTIONS.YEARS)}
                  </div>
                </div>
                <div className="form-row">
                  <div className="input-title">
                    {text.phone}
                    {phoneErr && <span className="error-text">{phoneErr}</span>}
                  </div>
                  <InputPhoneCustomStyle
                    placeholder="081 1234 564"
                    value={phone}
                    onChange={props.handlePhone}
                    country="TH"
                    limitMaxLength
                  />
                </div>
                <div className="form-row">
                  <div className="input-title">
                    {text.email}
                  </div>
                  <input
                    name="email"
                    value={email}
                    onChange={props.handleChangeInfo}
                    className="col-3"
                    type="email"
                    placeholder="jane@mockup.com"
                    required
                  />
                </div>
                <div className="form-row term-text">
                  <input
                    type="checkbox"
                    className="term-checkbox"
                    name="acceptTerms"
                    defaultChecked={acceptTerms}
                    onClick={props.handleChangeCheckbox}
                  />
                  <label>
                    {text.term.IAgreeToThe}
                    <a href="/terms-and-condition" target="blank">
                      {text.term.termAndConditions}
                    </a>
                    {text.and}
                    <a href="/privacy-policy" target="blank">
                      {text.term.privacyPolicy}
                    </a>
                  </label>
                  {termsErr && <div className="error-text">{termsErr}</div>}
                </div>
                <SubmitButton type="submit">{text.register}</SubmitButton>
              </form>
            </div>
          </OpdFormStyle>
        )}
    </Transition.Group>
  )
}


export default OpdFormInput
