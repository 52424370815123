export const MAX_USER_AGE = 80
export const monthOptions = [
  { key: 'January', text: 'January', value: 1 },
  { key: 'February', text: 'February', value: 2 },
  { key: 'March', text: 'March', value: 3 },
  { key: 'April', text: 'April', value: 4 },
  { key: 'May', text: 'May', value: 5 },
  { key: 'June', text: 'June', value: 6 },
  { key: 'July', text: 'July', value: 7 },
  { key: 'August', text: 'August', value: 8 },
  { key: 'September', text: 'September', value: 9 },
  { key: 'October', text: 'October', value: 10 },
  { key: 'November', text: 'November', value: 11 },
  { key: 'December', text: 'December', value: 12 },
]

export const dayOptions = () => {
  const options = []
  for (let day = 1; day <= 31; day++) {
    options.push({
      key: day,
      text: day,
      value: day,
    })
  }
  return options
}

export const yearOptions = () => {
  const options = []
  const convertBEtoAD = process.env.REACT_APP_YEAR_TYPE === 'BE' ? 543 : 0
  const today = new Date()
  const currentYear = today.getFullYear()
  for (
    let year = currentYear + convertBEtoAD;
    year > currentYear - MAX_USER_AGE;
    year--
  ) {
    options.push({ key: `year${year}`, text: year, value: year })
  }
  return options
}

export const DATE_OPTIONS = {
  DAYS: dayOptions(),
  MONTHS: monthOptions,
  YEARS: yearOptions(),
}
