import React from 'react'
import axios from 'axios'
import * as R from 'ramda'
import EmbassyForm from './embassyForm'
import hospitalURL from '../../utils/requestURL'

const getCountry = (address_components) => {
  for (let i = 0; i < address_components.length; i++) {
    if (address_components[i].types[0] == 'country') {
      return address_components[i].short_name
    }
    if (address_components[i].types.length == 2) {
      if (address_components[i].types[0] == 'political') {
        return address_components[i].short_name
      }
    }
  }
  return null
}

const GeocoderReverse = async ({ lat, lon }) => {
  // https://maps.googleapis.com/maps/api/geocode/json?sensor=false&key=...&latlng=13.756808629609388%2C100.57173924226122
  const { data } = await axios({
    url: 'https://maps.googleapis.com/maps/api/geocode/json',
    method: 'GET',
    params: {
      sensor: false,
      key: process.env.REACT_APP_GEOCODING_TOKEN,
      latlng: `${lat},${lon}`,
    },
  })
  console.log('google geolocation response -> ', data)
  return R.pipe(
    R.pathOr([], ['results']),
    R.head,
    R.pathOr([], ['address_components']),
  )(data)
}

const getCountryFromGoogle = async (lat, lon) => {
  try {
    const result = await GeocoderReverse({ lat, lon })
    return getCountry(result)
  } catch (error) {
    console.error(error)
    return error
  }
}

const createEmbassyUser = async (body) => {
  const getToken = await axios({
    method: 'post',
    url: hospitalURL('getToken'),
  })

  const token = R.pathOr(null, ['data', 'data', 'token'], getToken)
  if (!token) {
    const result = { data: { message: "can't get token" } }
    return result
  }

  const result = await axios({
    method: 'post',
    url: hospitalURL('embassy'),
    headers: {
      'x-api-key': token,
    },
    data: body,
  })
  return result
}

const embassyFormIndex = () => (
  <EmbassyForm
    createEmbassyUser={createEmbassyUser}
    getCountryFromGoogle={getCountryFromGoogle}
  />
)

export default embassyFormIndex
