const text = {
  personalInformation: 'Personal information',
  hospitalityNumber: 'Hospitality Number (HN)',
  firstname: 'Firstname',
  lastname: 'Lastname',
  gender: 'Gender',
  male: 'Male',
  female: 'Female',
  dateOfBirth: 'Date of Birth',
  phone: 'Phone',
  email: 'Email',
  register: 'Register',
  citizenId: 'Citizen ID',
  and: 'and',
  term: {
    IAgreeToThe: 'I agree to the',
    termAndConditions: 'Terms and Conditions',
    privacyPolicy: 'Privacy policy',
  },
}

export default text
