import styled from 'styled-components'

export const SubmitButton = styled.button`
  color: #fff;
  height: 40px;
  margin: auto;
  display: block;
  font-size: 16px;
  width: 220px;
  border-radius: 28px;
  background-color: #2fc2be;
  border: 1px solid #2fc2be;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  :hover {
    cursor: pointer;
  }

  :focus {
    outline: none;
    box-shadow: 0px 1px 4pt 0pt #6da3f8;
  }
`

export const CompleteForm = styled.div`
  max-width: 700px;
  min-height: 256px;
  margin: auto;
  margin-top: 60px;
  background: #fff;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  text-align: left;
  padding: 20px 8%;
  .icon-img {
    margin: auto;
    margin-bottom: 20px;
    display: block;
  }
  .header-text {
    text-align: center;
    font-size: 24px;
    color: #0055b8;
    margin-bottom: 10px;
  }
  .sub-header-text {
    text-align: center;
    font-size: 15px;
    font-weight: 200;
    color: #0055b8;
    margin:15px 0;
  }
  .bt-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .next-bt {
    margin: auto;
    display: block;
  }
  .back-bt {
    margin: 0 20px 10px 0;
    background: #fff;
    color: #2fc2be;
  }
`
