import React from 'react'
import {
  Transition,
  Loader,
  TransitionablePortal,
  Segment,
} from 'semantic-ui-react'
import { SummaryInfoStyle, SubmitButton, SegmentForm } from './style'
import { FADE_DURATION, STEP } from '../configEmbassy'
import text from './text'

const errorSignStyle = {
  background: '#fd5353',
  color: '#fff',
  right: '20%',
  position: 'fixed',
  top: '10%',
  zIndex: 1000,
}

const SummaryInfo = ({
  isShow,
  loading,
  showError,
  errorMessage,
  information: {
    firstname,
    lastname,
    email,
    gender,
    phone,
    birthdate,
    note,
    citizenId,
  },
  ...props
}) => {
  const renderCol = (name, value) => (
    <div className="summary-row">
      <div className="summary-col-name">{name}</div>
      <div className="summary-col">
        {value}
      </div>
    </div>
  )

  return (
    <SegmentForm>
      <Loader active={loading} />
      <Transition.Group animation="fade" duration={FADE_DURATION}>
        {isShow && (
          <SummaryInfoStyle>
            <div className="form-header">
              {text.summaryInformation}
            </div>
            <div className="summary">
              {renderCol(text.firstname, firstname)}
              {renderCol(text.lastname, lastname)}
              {renderCol(text.gender, gender)}
              {renderCol(text.dateOfBirth, birthdate)}
              {renderCol(text.phone, phone)}
              {renderCol(text.email, email)}
              {renderCol(text.citizenId, citizenId)}
            </div>
            <div className="form-header" style={{ display: 'none' }}>
              {text.note}
            </div>
            <textarea
              style={{ display: 'none' }}
              name="note"
              onChange={props.handleChangeInfo}
              defaultValue={note}
            />
            <div className="summary-bt-row ">
              <SubmitButton
                className="summary-back-bt"
                onClick={() => props.moveStepTo(STEP.FILL_FORM)}
                type="button"
              >
                {text.back}
              </SubmitButton>
              <SubmitButton
                className="summary-next-bt"
                onClick={props.onSubmitForm}
                type="button"
              >
                {text.register}
              </SubmitButton>
            </div>
          </SummaryInfoStyle>
        )}
      </Transition.Group>
      <TransitionablePortal onClose={props.handleCloseError} open={showError}>
        <Segment style={errorSignStyle}>
          <h3>
            {text.sorry}
          </h3>
          <p>{errorMessage}</p>
        </Segment>
      </TransitionablePortal>
    </SegmentForm>
  )
}

export default SummaryInfo
