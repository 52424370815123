export default `
# ข้อตกลงและเงื่อนไข
**1\. นิยาม (Definition)**
--------------------------

บริษัท เทเลเมดิก้า จำกัด (“บริษัท”) ให้บริการแพลตฟอร์มเทคโนโลยีที่ประกอบไปด้วยเว็บไซต์ [ooca.co](http://ooca.co) และแอปพลิเคชันอูก้า (ooca) (รวมทั้งหมดเรียกว่า “แอปพลิเคชัน”,”อูก้า”) ซึ่งให้บริการแพลตฟอร์มเทคโนโลยีในการสื่อสารแพทย์ทางไกล (Telemedicine) ให้แก่ผู้ใช้บริการที่ต้องการคำแนะนำ (“ผู้ใช้” หรือ ”ท่าน”) จากแพทย์หรือบุคคลากรทางการแพทย์ (รวมเรียกว่า ”แพทย์”,“ผู้ให้คำปรึกษา”)

ท่านต้องยอมรับและเห็นด้วยที่จะดำเนินการใช้งานภายใต้ข้อกำหนด เงื่อนไข และนโยบายที่ปรากฎบนแอบพลิเคชัน

ถ้าท่านไม่พึงประสงค์ที่จะใช้แอบพลิเคชันภายใต้ข้อตกลงและเงื่อนไขนี้ ท่านจะไม่สามารถใช้บริการแอบพลิเคชันหรือบริการอย่างอื่นที่เกี่ยวข้องกับแอบพลิเคชันนี้ได้

**2\. การให้บริการทางสุขภาพ (Health care Service)**
---------------------------------------------------

บริษัทไม่ได้ประกอบธุรกิจสถานพยาบาล หรือประกอบวิชาชีพเวชกรรม หรือให้คำแนะนำ และบริษัทไม่ได้แทรกแซงการประกอบวิชาชีพเวชรกรรมของแพทย์ หรือการให้คำแนะนำของผู้ให้คำปรึกษา

แพทย์และผู้ให้คำปรึกษาผ่านแอบพลิเคชันทุกคน ไม่ได้เป็นลูกจ้างหรือบุคคลที่บริษัทจัดหามาให้บริการแก่ท่าน แพทย์และผู้ให้คำปรึกษาเป็นผู้เลือกประกอบวิชาชีพเวชกรรมหรือให้คำแนะนำผ่านแอบพลิเคชันอูก้าอย่างอิสระ เพื่อให้คำปรึกษาแนะนำเกี่ยวกับปัญหาสุขภาพหรือให้การรักษาในบางกรณีแก่ผู้รับคำแนะนำ ซึ่งแพทย์และผู้ให้คำปรึกษาแต่ละคนจะรับผิดชอบต่อการให้คำแนะนำและการร้องเรียนของผู้รับคำแนะนำแต่ละท่านเอง บริษัทหรือบุคคลภายนอกที่นำเสนอบริการ หรือจัดหาลิงก์ (link) นั้น ไม่มีความรับผิดชอบต่อคำแนะนำใดๆ ที่ผู้รับคำแนะนำได้รับจากแพทย์หรือผู้ให้คำปรึกษา

**3\. เนื้อหาในแอบพลิเคชัน (Site Content)**
-------------------------------------------

ไม่มีเนื้อหาอื่นใดในแอบพลิเคชัน(นอกเหนือจากคำแนะนำที่ได้รับจากแพทย์หรือผู้ให้คำปรึกษา) ที่นับเป็นคำแนะนำทางการแพทย์ หรือเป็นการรับประกัน หรือการสนับสนุนว่า ยาหรือการรักษาใดๆ นั้นปลอดภัยและได้ผลสำหรับท่าน

**4\. การยินยอมเข้ารับบริการ (Inform Consent)**
-----------------------------------------------

**ก.** การแพทย์ทางไกล (Telehealth) คือการให้บริการทางสุขภาพโดยใช้เทคโนโลยีโต้ตอบทางภาพเคลื่อนไหวและเสียงโดยผู้ป่วย และบุคลากรทางการแพทย์นั้นไม่ได้อยู่ในสถานที่ทางกายภาพเดียวกัน ระหว่างการให้คำปรึกษาสุขภาพทางไกล ท่านอาจต้องให้ข้อมูลสนทนาเกี่ยวกับรายละเอียดของประวัติทางสุขภาพและประวัติส่วนตัวของท่าน ผ่านการโต้ตอบผ่านภาพเคลื่อนไหวและเสียง หรือเทคโนโลยีทางการสื่อสารทางไกลอื่นๆ และ แพทย์หรือผู้ให้คำปรึกษานั้นอาจซักประวัติหรือสังเกตหรือขอให้ท่านตรวจร่างกายตนเองเพื่อประกอบการวินิจฉัยและให้คำแนะนำผ่านเทคโนโลยีดังกล่าว

**ข.** การรับคำแนะนำทางสุขภาพทางไกลจากผู้ให้คำปรึกษาจากกลุ่มแพทย์หรือผู้ให้คำปรึกษานั้นไม่สามารถแทนที่การพบแพทย์ที่สถานพยาบาล หรือแทนที่การรักษาอย่างต่อเนื่องกับแพทย์ที่รักษาประจำได้ 

ท่านอาจเข้ารับการให้คำแนะนำต่อเนื่องกับแพทย์หรือผู้ให้คำปรึกษาบางท่านผ่านแอบพลิเคชัน ซึ่งในการเข้าพบครั้งแรกกับผู้ให้คำปรึกษาเป็นเพียงการเริ่มการรับคำปรึกษา (เพื่อพิจารณา แนวทางในการรักษาที่เหมาะสมที่สุดในการดูแลสำหรับท่าน) และไม่จำเป็นจะต้องพบแพทย์ต่อเนื่องผ่านแอบพลิเคชันเสมอไป แพทย์หรือผู้ให้คำปรึกษาผ่านแอบพลิเคชันอาจแนะนำให้ผู้รับคำแนะนำมองหาความช่วยเหลือทางการแพทย์ฉุกเฉิน การตรวจติดตามการรักษาจากแพทย์ที่สถานพยาบาล หรือไปตรวจรักษากับแพทย์ที่สถานพยาบาล ซึ่งผู้รับคำแนะนำควรปฏิบัติตามคำแนะนำดังกล่าวเพื่อความปลอดภัยของผู้รับคำแนะนำ

บริษัทอาจทำการนัดหมายสำหรับการตรวจติดตาม ผ่านแอบพลิเคชัน หรือช่องทางอื่นที่ท่านได้ระบุไว้ ท่านอาจจะได้รับการติดต่อจากฝ่ายสนับสนุนบริการลูกค้า (Customer support service)  เพื่อติดตามผลหลังจากการได้รับการรักษา ผลข้างเคียง หรือผลแทรกซ้อนอื่นๆ ซึ่งประโยชน์ของการใช้แอบพลิเคชัน คือการเพิ่มความสามารถในการเข้าถึงกลุ่มวิชาชีพทางการแพทย์และอำนวยความสะดวกสบายให้แก่ท่าน แต่อย่างไรก็ตาม การให้บริการทางการแพทย์ทางไกลนั้นมีความเสี่ยงเช่นเดียวกันกับการให้การบริการทางสุขภาพอื่นๆ และความเสี่ยงที่อาจเกิดขึ้น อาจเป็นข้อจำกัดทางเทคนิคอันได้แก่

*   ข้อมูลที่ส่งต่อนั้นไม่เพียงพอ (เช่น ความคมชัดของภาพไม่เพียงพอ) ที่จะสามารถช่วยให้ท่านตัดสินใจในการรับการรักษาได้ 
    
*   ความล่าช้าของสัญญาณ หรือการขาดช่วงของสัญญา ที่ทำให้สัญญาณหลุดหรือสื่อสารไม่ได้ ซึ่งสามารถเกิดได้จากความผิดปกติของเครื่องมืออิเล็กทรอนิกส์ ถ้าเหตุการณ์เหล่านี้เกิดขึ้น ท่านอาจได้รับการติดต่อผ่านโทรศัพท์ หรือ การสื่อสารผ่านช่องทางอื่นๆ
    
*   ข้อจำกัดในการรับทราบประวัติทางการแพทย์ของท่านอาจจะส่งผลให้การแนะนำที่ปราศจากประวัติข้อมูลทางการแพทย์ของท่านผิดพลาด และอาจส่งผลให้ท่านเกิดอาการแพ้ยา หรือมีผลแทรกซ้อน หรือเกิดความผิดพลาดอื่นๆ ได้
    
*   ถึงแม้ว่าบริษัทจะมีมาตรการป้องกันความเป็นส่วนตัวและความปลอดภัยของข้อมูลผู้ป่วย แต่ในบางกรณี มาตรการนั้นอาจล้มเหลว ทำให้เกิดการรั่วไหลของข้อมูลได้
    

**ค. **การยอมรับข้อตกลงและเงื่อนไขดังกล่าวท่านรับทราบและเข้าใจและยอมรับว่า:

*   ท่านเข้าใจว่าท่านสามารถคาดหวังประโยชน์จากการใช้แอบพลิเคชัน แต่ไม่มีการรับรองผลใดๆ
    
*   ท่านเข้าใจว่า กฎหมายและข้อบังคับที่คุ้มครองความเป็นส่วนตัวและความปลอดภัยของข้อมูลทางสุขภาพนั้นสามารถใช้กับการให้บริการทางแพทย์ทางไกลได้ และได้รับจดหมายเตือนเกี่ยวกับความเป็นส่วนตัวจากทางบริษัทแล้ว ข้อมูลการสื่อสารอิเล็กทรอนิกส์และประวัติการรักษาจะผ่านการเข้ารหัสความปลอดภัยเสมอ
    
*   ถ้าประกันสุขภาพของท่านไม่สามารถครอบคลุมค่าใช้จ่ายที่เกิดขึ้นผ่านการให้บริการ ท่านต้องรับผิดชอบต่อค่าใช้จ่ายนั้น
    
*   แพทย์/ผู้ให้คำปรึกษาอาจพิจารณาว่าการให้บริการผ่านแอบพลิเคชันนั้นไม่เหมาะสมกับการรักษาภาวะเจ็บป่วยของท่าน และอาจเลือกที่จะไม่ให้บริการแก่ท่านผ่านแอบพลิเคชัน
    
*   ในการรักษาจิตบำบัด ท่านจะได้รับข้อมูลเกี่ยวกับวิธีการรักษา ระยะเวลาของการรักษา(ถ้าทราบ) และค่าใช้จ่าย ถ้าท่านสามารถขอความเห็นจากจิตแพทย์หรือนักจิตวิทยาท่านอื่นหรือสิ้นสุดการรักษาได้เสมอ
    

**ง.** ท่านสามารถส่งข้อความไปยังแพทย์หรือผู้ให้คำปรึกษาของท่านผ่านทาง customer service ซึ่งแพทย์/ผู้ให้คำปรึกษาไม่อาจจะตอบกลับข้อความได้ทันที่ หากท่านมีภาวะฉุกเฉินหรือต้องการความช่วยเหลือฉุกเฉิน กรุณาติดต่อ 1669 หรือสถานพยาบาลที่ใกล้ที่สุด 

**5. ความเป็นส่วนตัว** 
-----------------------

บริษัทให้ความสำคัญกับความปลอดภัยของข้อมูลผู้ใช้ที่ต้องปกปิดเป็นความลับ โดยมีแนวทางในการปกปิดข้อมูลตาม**นโยบายความเป็นส่วนตัว (privacy policy) **ที่บริษัทในประกาศไว้ โดยบริษัทจะใช้ความระมัดระวังเกี่ยวกับมาตรการรักษาความปลอดภัยของบริษัทเพื่อให้มีการรักษาความปลอดภัยของข้อมูลใดๆ ของผู้ใช้ 

**6\. บัญชีผู้ใช้** 
--------------------

ท่านต้องสร้างบัญชีผู้ใช้เพื่อเข้าใช้แอบพลิเคชัน ในการลงทะเบียนท่านจำเป็นต้องกรอก ชื่อ นามสกุล, อีเมล์, รหัสผ่าน และข้อมูลอื่นๆ ที่เกี่ยวข้อง (ต่อไปนี้เรียกว่า “ข้อมูลบัญชี”) โดยในการสร้างบัญชีท่านต้องบรรลุนิติภาวะ ผู้ที่ยังไม่บรรลุนิติภาวะไม่อนุญาตให้สร้างบัญชีเองได้ ท่านต้องรับรองว่าข้อมูลผู้ใช้ของท่านเป็นข้อมูลจริง ถูกต้อง และสมบูรณ์ทุกครั้งที่ท่านเข้าใช้แอบพลิเคชัน ท่านจะต้องไม่เปิดเผยหรือแบ่งปันรหัสผ่านของท่านให้กับผู้อื่น และต้องรักษารหัสผ่านของท่าน เพื่อให้มั่นใจได้ว่าจะไม่มีใครสามารถเข้าบัญชีในชื่อของท่านได้นอกจากท่าน บริษัทจะไม่สามารถเข้าบัญชีหรือนำข้อมูลผู้ใช้ของท่านไปใช้เพื่อประโยชน์การอื่นที่ไม่เกี่ยวข้องกับแอบพลิเคชันได้  

**7\. การให้บริการแก่ผู้ที่ยังไม่บรรลุนิติภาวะ**
------------------------------------------------

 ผู้ที่ยังไม่บรรลุนิติภาวะสามารถใช้แอบพลิเคชันได้ภายใต้การดูแลและการให้คำยินยอมจากผู้ปกครองหรือผู้แทนโดยชอบธรรม โดยผู้ปกครองหรือผู้แทนโดยชอบทำต้องเป็นผู้รับผิดชอบในการสร้างบัญชีผู้ใช้ ยอมรับและให้คำยินยอมตามข้อกำหนดและเงื่อนไขให้แก่ผู้ยังไม่บรรลุนิติภาวะ 

**8\. สิทธิการเข้าใช้**
-----------------------

 บริษัทขอสงวนสิทธิการเข้าใช้ให้เฉพาะแก่ท่านที่ให้ข้อมูลและลงทะเบียนสร้างบัญชีผู้ใช้แอบพลิเคชันเท่านั้น บริษัทขอสงวนสิทธิในการยกเลิกหรือปฎิเสธการให้บริการแก่ท่านไม่ว่าด้วยเหตุผลใด ท่านจะไม่ปฏิบัติหรือพยามที่จะกระทำการใดดังต่อไปนี้

**ก.** ปลอมแปลงหรือบิดเบือนข้อมูลเพื่อสวมรอยเป็นบุคคลอื่นหรือทำให้เข้าใจว่าตนเป็นบุคคลอื่น

**ข.** ใช้แอบพลิเคชันเพื่อกระทำผิดกฎหมายหรือกฎระเบียบของสังคม

**ค.** คัดลอก ดัดแปลง แก้ไข ปรับปรุงแอบพลิเคชันหรือส่วนใดส่วนหนึ่งของแอบพลิเคชัน

**ง.** เผยแพร่หรือใช้แอบพลิเคชันในการกระจายไวรัสหรือข้อมูลที่ไม่ปลอดภัย

**จ.** การกระทำใดๆ ที่นอกเหนือไปจากการใช้งานโดยทั่วไปตามวัตถุประสงค์ที่บริษัทระบุไว้ หรือไม่ปฎิบัติข้อตกลงและเงื่อนไขในการให้บริการนั้นๆ นอกจากนี้ท่านจะต้องไม่กระทำพฤติกรรมหรือคำพูดไม่เหมาะสม, รุนแรง, ผิดศีลธรรมอันดีของสังคมหรือกฎหมาย ทางบริษัทจะไม่รับผิดชอบการกระทำใดๆ ของท่านที่เกิดขึ้นระหว่างการใช้แอบพลิเคชัน บริษัทไม่แนะนำอย่างยิ่งในการใช้งานแอบพลิเคชันผ่านคอมพิวเตอร์หรืออุปกรณ์สาธารณะ และไม่แนะนำให้เก็บหรือจดจำข้อมูลผู้ใช้โดยเฉพาะรหัสผ่านในเว็บบราวเซอร์หรือโปรแกรมอื่นๆ 

**9\. ค่าบริการ ค่าธรรมเนียม และข้อตกลงการชำระเงิน** 
-----------------------------------------------------

บริษัทให้บริการเชื่อมต่อสื่อสารระหว่างผู้ใช้กับแพทย์/ผู้ให้คำปรึกษาโดยไม่คิดค่าบริการใดๆ กับผู้ใช้ แต่อาจมีการเรียกเก็บค่าธรรมเนียมแพทย์ หรือค่าบริการให้คำปรึกษาโดยแพทย์/ผู้ให้คำปรึกษา ซึ่งบริษัทจะเป็นผู้เรียกเก็บค่าธรรมเนียมหรือค่าบริการต่างๆ แทนแพทย์/ผู้ให้คำปรึกษาโดยใช้ข้อมูลบัตรเครดิตหรือข้อมูลการชำระเงินช่องทางอื่นๆ ที่ท่านลงทะเบียนไว้กับบริษัท ท่านยินยอมให้บริษัทเรียกเก็บเงินจากบัตรเครดิตหรือข้อมูลการชำระเงินช่องทางอื่นๆ ทันทีที่ท่านได้รับการบริการที่คิดค่าบริการ ซึ่งท่านอาจไม่ได้รับการแจ้งเตือนหรือใบเรียกเก็บค่าบริการก่อน

ในการนัดหมายแต่ละครั้งผู้ให้คำปรึกษาได้มีการเตรียมตัวเพื่อให้บริการแก่ผู้ใช้โดยเฉพาะ หากมีการยกเลิกนัดหมาย หรือผิดนัดหมาย หรือไม่ได้รับคำปรึกษา ผู้ใช้อาจได้รับค่าธรรมเนีนมคืนหรือไม่ได้คืนขึ้นอยู่กับบริบทนั้นๆ ผู้ใช้สามารถตรวจสอบเงื่อนไขการยกเลิกนัดหมาย การผิดนัดหมาย และไม่ได้รับคำปรึกษา ดังนี้

### **1\. การยกเลิกนัดหมายล่วงหน้า**

*   ยกเลิกโดยผู้ให้คำปรึกษา
    

*   หากผู้ให้คำปรึกษายกเลิกการนัดหมาย ผู้ใช้จะได้รับเงินคืนในกระเป๋าเงิน (wallet) สำหรับใช้ในการสร้างการนัดหมายใหม่เต็มจำนวน
    

*   ยกเลิกโดยผู้ใช้
    

*   หากไม่สามารถมารับบริการได้ตามเวลาที่ตนเองนัดหมายไว้ ผู้ใช้จำเป็นต้อง ยกเลิกนัดหมายด้วยตัวเองก่อนเวลานัดอย่างน้อย  1 ชั่วโมงโดยจะได้เงินคืน ลงกระเป๋าเงิน (wallet) สำหรับใช้ในการสร้างการนัดหมายใหม่เต็มจำนวน 
    
*   หากยกเลิกนัดน้อยกว่า 1 ชั่วโมงก่อนเวลานัดหมาย ผู้ใช้จะไม่ได้รับเงินคืนโดยจะเสียค่าบริการให้ผู้ให้คำปรึกษา เป็นค่าชดเชยการเสียเวลา แต่ผู้ใช้ยังสามารถปรึกษากับผู้ให้คำปรึกษาท่านเดิมได้ โดยเจ้าหน้าที่บริการจะเป็นผู้เลื่อนเวลานัดหมายใหม่กับผู้ให้คำปรึกษาท่านเดิมให้ ทั้งนี้ จะพิจารณาเป็นกรณีๆ ไป เช่น ไม่ได้รับการแจ้งเตือนการมีนัดหมาย หรือสับสนเรื่องเขตเวลา เป็นต้น
    

### **2\. การยกเลิกนัดหมาย Walk-in**

*   ยกเลิกโดยผู้ให้คำปรึกษา
    

*   หากผู้ให้คำปรึกษายกเลิกการนัดหมาย ผู้ใช้จะได้รับเงินคืนในกระเป๋าเงิน (wallet) สำหรับใช้ในการสร้างการนัดหมายใหม่เต็มจำนวน
    

*   ยกเลิกโดยผู้ใช้
    

*   ผู้ใช้จำเป็นต้องยกเลิกนัดหมายในทันที หรือภายใน 5 นาทีหลังจากจอง เรียบร้อยแล้ว จึงจะได้เงินคืนลงกระเป๋าเงินเต็มจำนวน 
    

### **3.การผิดนัดหมาย หรือไม่มาตามนัดหมาย**

*   ผู้ให้คำปรึกษาผิดนัดหมาย
    

*   หากถึงเวลานัดหมายแล้วผู้ให้คำปรึกษาไม่ติดต่อมา ผู้ใช้สามารถติดต่อมาที่เจ้าหน้าที่บริการได้ทันที
    
*   หลังจากผ่านไป 10 นาที หากยังติดต่อผู้ให้คำปรึกษาไม่ได้ ผู้ใช้จะได้รับเงินคืนในกระเป๋าเงิน (wallet) สำหรับใช้ในการสร้างการนัดหมายใหม่เต็มจำนวน
    

*   ผู้ใช้ผิดนัดหมาย
    

*   หากผู้ใช้ไม่มาตามนัดภายใน 10 นาทีนับตั้งแต่เวลานัด ระบบจะทำการยกเลิกนัดหมายทันที โดยถือว่าผู้ใช้ไม่มาตามนัดหมาย และไม่ได้รับเงินคืน โดยเสียค่าบริการให้ผู้ให้คำปรึกษา เป็นค่าชดเชยการเสียเวลา แต่ผู้ใช้ยังสามารถปรึกษากับผู้ให้คำปรึกษาท่านเดิมได้ โดยเจ้าหน้าที่บริการจะเป็นผู้เลื่อนเวลานัดหมายใหม่กับผู้ให้คำปรึกษาท่านเดิมให้ ทั้งนี้ จะพิจารณาเป็นกรณีๆ ไป เช่น ไม่ได้รับการแจ้งเตือนการมีนัดหมาย หรือสับสนเรื่องเขตเวลา เป็นต้น
    

### **4\. ปัญหาที่ทำให้ไม่ได้รับคำปรึกษา**

*   ผู้ใช้สามารถแจ้งขอความช่วยเหลือจากเจ้าหน้าที่บริการได้ทันทีระหว่างการสนทนาโดยไม่ต้องวางสาย
    
*   หากให้คำปรึกษาโดยใช้เวลาไม่ถึง 10 นาที และเกิดปัญหาการใช้งาน เจ้าหน้าที่บริการจะให้การช่วยเหลือเต็มความสามารถ แต่หากภายหลังการช่วยเหลือไปแล้วผู้ใช้ไม่ประสงค์จะรับคำปรึกษาต่อ ผู้ใช้จะได้รับเงินคืนในกระเป๋าเงิน (wallet) สำหรับใช้ในการสร้างการนัดหมายใหม่เต็มจำนวน
    
*   หากให้คำปรึกษาโดยใช้เวลาไม่ถึง 25 นาที และเกิดปัญหาการใช้งาน เจ้าหน้าที่บริการจะให้การช่วยเหลือเต็มความสามารถ แต่หากภายหลังการช่วยเหลือไปแล้วผู้ใช้ไม่ประสงค์จะรับคำปรึกษาต่อ เจ้าหน้าที่บริการจะจัดการหาวันและเวลานัดใหม่กับผู้ให้คำปรึกษาท่านเดิม แต่จะไม่ดำเนินการคืนเงินให้ผู้ใช้เพื่อใช้จองกับผู้ให้คำ  ปรึกษาท่านอื่น
    
*   หากให้คำปรึกษาไปแล้วเกิน 25 นาที และเกิดปัญหาการใช้งาน เจ้าหน้าที่บริการจะให้การช่วยเหลือเต็มความสามารถ แต่หากภายหลังการช่วยเหลือไปแล้วยังไม่สามารถให้การปรึกษากันต่อไปได้ ผู้ให้คำปรึกษาจะพิจารณาสรุปการปรึกษาไว้ใน visit note หรือขอติดต่อกับผู้ใช้เพื่อให้คำปรึกษาต่อ ทั้งนี้ขึ้นอยู่กับดุลพินิจของผู้ให้คำปรึกษา
    

หากผู้ใช้เป็นบุคลากรในหน่วยงานที่ทำข้อตกลงกับบริษัท สามารถใช้แอบพลิเคชันในการขอรับคำปรึกษาโดยไม่เสียค่าธรรมเนียมแพทย์ หรือค่าบริการให้คำปรึกษา ทั้งนี้ ผู้ใช้ต้องยินยอมตามเงื่อนไขการให้บริการบุคลากรในหน่วยงานที่ทำข้อตกลงกับบริษัท จึงสามารถใช้บริการโดยไม่เสียต่าธรรมเนียมได้ หากผู้ใช้ไม่ยินยอมตามเงื่อนไชดังกล่าว ผู้ใช้ยังสามารถใช้งานแอบพลิเคชันต่อไปได้ แต่จะมีค่าบริการที่เกี่ยวข้องเกิดขึ้น

ในกรณีที่ผู้ใช้ต้องการเรียกร้องค่าบริการ/ค่าธรรมเนียมคืน ผู้ใช้สามารถรับเงินคืนได้ในรูปแบบกระเป๋าเงิน(Wallet) โดยจากกรณีดังต่อไปนี้

*   เกิดปัญหาหรือความยุ่งยากทางเทคนิคที่ชัดเจนอันมีสาเหตุจากแพลตฟอร์มหรือเซิร์ฟเวอร์ ของ ooca เองและส่งผลให้การพุดคุยกับนักจิตแพทย์/นักจิตวิทยานั้นไม่สำเร็จ (ทั้งนี้ ไม่รวม สาเหตุอันเกิดจากอุปกรณ์หรือการเชื่อมต่อของผู้ใช้ เว้นแต่มีเหตุอันสมควร โดยพิจารณาเป็นรายกรณี)
    
*   การยกเลิกนัดหมายของผู้ใช้ก่อนถึงเวลานัดหมายไม่น้อยกว่า 1 ชั่วโมง
    
*   มีการยกเลิกนัดหมายจากผู้ให้คำปรึกษาในทุกกรณี
    
*   กรณีที่ผู้ใช้บริการไม่พึงพอใจการให้บริการ ซึ่งทาง ooca จะดำเนินการสอบสวนในกรณี ดังกล่าวก่อนที่จะมีการดำเนินการคืนการชำระเงิน
    
*   การร้องขอเพื่อที่จะคืนเงินผู้ใช้โดยผู้ให้คำปรึกษาเอง
    
*   ในกรณีอื่นๆ ที่มีอาจมีสืบเนื่องจากการเปลี่ยนแปลงข้อมูลสัญญาต่อไปในอนาคต
    

หากผู้ใช้ต้องการถอนเงินออกจากกระเป๋าเงิน(Wallet) วงเงินที่สามารถถอนเงินได้นั้น จะต้องไม่ใช่วงเงินที่ได้รับเงินคืนจากกรณีที่กล่าวมาข้างต้น โดยทาง ooca จะพิจารณาและดำเนินการให้ภายใน 90 วันหลังจากผู้ใช้ร้องขอถอนเงินมาที่ [support@ooca.co](mailto:support@ooca.co) 

**10\. เงื่อนไข และความยินยอมของผู้ใช้องค์กร**
----------------------------------------------

บริษัทมีข้อตกลงการหน่วยงานหรือองค์กรต่างๆ เพื่อให้สิทธิในการใช้แอบพลิเคชันโดยไม่มีค่าบริการหรือค่าธรมมเนียมต่างๆ ซึ่งบุคลากรในหน่วยงานที่ได้รับสิทธิดังกล่าว (เรียกว่า ผู้ใช้องค์กร หรือ พนักงานองค์กร) ต้องยอมรับเงื่อนไข ข้อตกลงและให้ความยินยอม ดังต่อไปนี้

### 1\. เงื่อนไข และความยินยอมการใช้สิทธิผู้ใช้องค์กร

*   ผู้ใช้องค์กร ต้องลงทะเบียนสร้างบัญชีผู้ใช้ ด้วยชื่อบัญชีตามที่หน่วยงานหรือองค์กรกำหนดไว้ ซึ่งมักจะเป็นอีเมล์ของหน่วยงาน หรือเบอร์โทรศัพท์ที่ของท่านที่ให้ไว้กับบริษัท
    
*   สำหรับผู้ใช้องค์กรกำหนดให้สามารถขอรับคำปรึกษาได้ไม่เกิน 2 ครั้งต่อสัปดาห์ อย่างไรก็ตามหากพนักงานองค์กรมีเหตุผลความจำเป็นจริง สามารถขอรับคำปรึกษาได้แม้เคยใช้งานไปแล้วเกินจำนวนที่กำหนดไว้
    
*   ผู้ใช้องค์กร รับทราบดีว่า ข้อมูลทั้งหมดในการใช้แอบพลิเคชันของท่านแบบไม่ระบุตัวตน อาจถูกส่งต่อให้หน่วยงานของท่านที่มีข้อตกลงกับทางบริษัทเพื่อเรียกเก็บค่าบริการ ซึ่งเป็นข้อมูลที่ไม่สามารถใช้ระยุถึงตัวตนของท่านได้ และไม่เชื่อมโยงกับชื่อบัญชี หรือชุดข้อมูลอื่นที่เมื่อนำไปประกอบและจะสามารถทราบตัวตนของท่าน
    
*   บริษัทให้ความสำคัญกับการรักษาความปลอดภัยและความเป็นส่วนตัวของข้อมูลของท่านมาก บริษัทจะพยายามทุกวิถีทางเพื่อให้แน่ใจว่าข้อมูลของท่านภายในระบบจะถูกเก็บรักษาไว้โดยมีมาตรการรักษาความปลอดภัยและความเป็นส่วนตัวอย่างเหมาะสม อย่างไรก็ตาม บริษัทจำเป็นต้องปฎิบัติตามกฎหมายที่อาจจำเป็นต้องเปิดเผยข้อมูลของท่านให้เจ้าหน้าที่ที่เกี่ยวข้องทราบตามกฎหมาย หรือเป็นการกระทำเพื่อป้องกันภัยอันตรายที่อาจทำให้ถึงแก่ชีวิตของท่าน
    

### 2\. เงื่อนไขการถูกระงับสิทธิผู้ใช้องค์กรชั่วคราว

เพื่อให้การบริการมีประสิทธิภาพสูงสุดแก่ผู้ใช้ทุกท่าน และไม่ให้มีการนัดหมายที่เกินความจำเป็นที่อาจทำให้เสียโอกาสกับผู้ใช้ท่านอื่น ดังนั้น บริษัทจึงขออนุญาตสงวนสิทธิ์ในการระงับสิทธิผู้ใช้องค์กรชั่วคราวของผู้ที่มีพฤติกรรมต่อไปนี้ มากกว่า 1 ครั้งขึ้นไป

*   ผู้ใช้องค์กรที่ ไม่มาใช้บริการได้ตามเวลานัดหมาย โดยไม่ยกเลิกนัดหมายก่อนถึงเวลานัด
    
*   ผู้ใช้องค์กรที่ ยกเลิกการนัดหมายก่อนเวลา น้อยกว่า 1 ชั่วโมง
    
*   ผู้ใช้องค์กรที่ มีการยกเลิกนัด ก่อนเวลานัดหมาย มากกว่า 1 ครั้ง ในรอบ 1 เดือน
    
*   ผู้ใช้องค์กรที่ มีการนัดหมายจำนวนมากผิดปกติไม่จากพฤติกรรมการใช้งานที่เหมาะสม โดยไม่มีเหตุอันควร
    

โดยมีระยะเวลาในการระงับสิทธิจะทวีขึ้นในแต่ละครั้ง โดยครั้งแรกจะถูกระงับสิทธิ 1 สัปดาห์ ดังนี้

ระงับสิทธิครั้งที่ 1 ระยะเวลา 1 สัปดาห์

ระงับสิทธิครั้งที่ 2 ระยะเวลา 2 สัปดาห์

ระงับสิทธิครั้งที่ 3 ระยะเวลา 4 สัปดาห์

ระงับสิทธิครั้งที่ n ระยะเวลา 2(n-1) สัปดาห์

ผู้ใช้องค์กร สามารถอุทธรณ์ เพื่อถอนการระงับสิทธิ โดยส่งคำร้องและเหตุผลมาที่ [support@ooca.co](mailto:support@ooca.co)

ผู้ใช้องค์กร ยอมรับและตกลงที่จะปฎิบัติตามเงื่อนไขดังกล่าวข้างต้น โดยกดยอมรับข้อตกลงและเงื่อนไขทั้งหมดนี้ หากท่านไม่ยอมรับและให้ความยินยอมในเงื่อนไขของผู้ใช้องค์กร ท่านสามารถเลือกที่จะไม่ยอมรับข้อตกลงและเงื่อนไขทั้งหมด ซึ่งส่งผลให้ท่านไม่สามารถใช้งานแอบพลิเคชันได้ หรือท่านสามารถเลือกปฎิเสธและไม่ยอมรับหรือถอนความยินยอมเฉพาะเงื่อนไชผู้ใช้องค์กร เพื่อให้สามารถใช้แอบพลิเคชันแบบผู้ใช้งานทั่วไปได้ โดยแจ้งความประสงค์มาที่อีเมล์ [support@ooca.co](mailto:support@ooca.com)

**11\. เครื่องหมายการค้า **
---------------------------

ชื่อ โลโก้ และข้อความอื่นๆ ที่ปรากฎในแอบพลิเคชันเป็นส่วนหนึ่งของเครื่องหมายการค้าของบริษัท บริษัทไม่อนุญาตให้นำเครื่องหมายการค้านี้ไปใช้ประโยชน์ หรือ ทำการดัดแปลง แก้ไข ส่วนใดส่วนหนึ่งหรือทั้งหมดไปใช้งานในวัตถุประสงค์ใดๆ ก็ตาม โดยไม่ได้รับอนุญาตอย่างเป็นลายลักษณ์อักษรจากบริษัท

**12\. การระงับบัญชี **
-----------------------

ท่านสามารถระงับบัญชีได้โดยแจ้งความประสงค์มาที่อีเมล์ [support@ooca.co](mailto:support@ooca.com) บริษัทจะระงับบัญชีให้ท่าน ซึ่งหลังจากยกเลิกท่านจะไม่สามารถเข้าใช้บริการและบริษัทไม่มีหน้าที่ในการให้บริการท่านอีกต่อไป ข้อมูลส่วนตัวและข้อมูลสุขภาพของท่านจะไม่สามารถเข้าถึงได้อีก อย่างไรก็ตามท่านสามารถขอยกเลิกการระงับบัญชีเพื่อกลับมาใช้บริการภายหลังได้โดยแจ้งความประสงค์มาที่อีเมล์ข้างต้น

**13\. การแก้ไขข้อกำหนดและเงื่อนไข **
-------------------------------------

บริษัทอาจแก้ไขข้อกำหนดและเงื่อนไขเพิ่มเติม เมื่อบริษัทเห็นว่ามีความจำเป็น โดยไม่ต้องให้คำบอกกล่าวล่วงหน้าแก่ผู้ใช้ การแก้ไขจะมีผลบังคับเมื่อมีการประกาศข้อกำหนดและเงื่อนไขที่แก้ไขเพิ่มเติมที่แก้ไขไว้ในสถานที่ที่เหมาะสมภายในเว็บไซต์ที่บริษัทเป็นผู้ดำเนินงาน การที่ผู้ใช้ใช้บริการต่อไป ถือว่าผู้ใช้ได้ให้การยอมรับที่มีผลสมบูรณ์และเพิกถอนมิได้ต่อข้อกำหนดและเงื่อนไขที่แก้ไขแล้ว ผู้ใช้ควรตรวจสอบข้อกำหนดและเงื่อนไขเป็นประจำระหว่างการใช้บริการ เนื่องจากอาจไม่มีการแจ้งเตือนแยกต่างหากเกี่ยวกับการแก้ไขข้อกำหนดและเงื่อนไขดังกล่าว 

**14\. การรับประกัน**
---------------------

 ท่านต้องรับทราบว่าบริษัทไม่ได้เป็นผู้ให้บริการทางด้านสุขภาพ ไม่ได้เป็นผู้ให้คำแนะนำ, วินิจฉัย หรือรักษาปัญหาสุขภาพ แต่บริษัทให้บริการแอบพลิเคชันที่เชื่อมต่อท่านกับแพทย์/ผู้ให้คำปรึกษาซึ่งเป็นบุคลลที่สาม ดังนั้นบริษัทไม่รับประกันผลใดๆ จากคำแนะนำที่ท่านได้รับจากแพทย์/ผู้ให้คำปรึกษา ท่านต้องยอมรับว่าแพทย์/ผู้ให้คำปรึกษาไม่ได้เกี่ยวข้องกับหรือเป็นลูกจ้างบริษัท แต่เป็นแพทย์/ผู้ให้คำปรึกษาที่ประกอบวิชาชีพอย่างอิสระ ซึ่งเป็นผู้รับผิดชอบในการให้คำแนะนำ คำปรึกษา แนวทางการรักษาให้แก่ท่านโดยตรง

**15\. ใบประกอบวิชาชีพของผู้เชี่ยวชาญ**
---------------------------------------

แพทย์ทุกท่านที่ปรากฎในแอบพลิเคชันเป็นผู้มีความรู้ความสามารถในสาขานั้นๆ และมีใบประกอบวิชาชีพเวชรกรรม และใบอนุมัติบัติหรือวุฒิบัตรเฉพาะทางตามที่ได้ปรากฎข้อมูลในแอพลิเคชันจริง รวมถึงผู้ประกอบวิชาชีพอื่นและนักจิตวิทยา ก็เป็นผู้มีความรู้ความสามารถในสาขาของตน และมีใบประกอบวิชาชีพหรือปริญญาบัตรในสาขาของตน ถึงอย่างไรก็ตามท่านสามารถตรวจสอบข้อมูลของแพทย์ได้ผ่านเว็บไซต์ของแพทยสภา ([www.tmc.or.th](http://www.tmc.or.th/)) หรือเว็บไซต์ของหน่วยงานอื่นๆ ที่เกี่ยวข้องกับสาขาวิชาชีพอื่นๆ

**16\. ข้อมูลจากบุคคลภายนอก**
-----------------------------

ในแอบพลิเคชันอาจมีลิงก์เชื่อมโยงไปยังเนื้อหาของบุคคลภายนอก ท่านรับทราบและตกลงว่าบริษัทจะไม่รับผิดชอบในเนื้อหาการให้บริการข้อมูลของบุคคลภายนอก และบริษัทไม่ได้ควบคุม รับรอง สนับสนุน ให้การแนะนำหรือยอมรับในเนื้อหาดังกล่าว การที่ท่านรับบริการคำแนะนำจากการเชื่อมโยงไปยังเนื้อหาของบุคคลภายนอกใดๆ ถือเป็นความเสี่ยงของท่านเอง

**17\. การชดใช้ความเสียหาย**
----------------------------

ท่านตกลงที่จะชดใช้ ป้องกัน และไม่ทำให้เกิดความเสียหายต่ออูก้า ผู้ให้บริการทางการแพทย์ กรรมการ ตัวแทน หรือบริษัทในเครือ จากการละเมิดซึ่งข้อตกลงและเงื่อนไขนี้ 

**18\. ข้อจำกัดทางภูมิศาสตร์**
------------------------------

บริษัทไม่รับรองว่าการให้บริการและ/หรือ คำแนะนำต่างๆ ผ่านการใช้บริการของท่านจะมีความเหมาะสม หรือสามารถใช้บริการได้ในต่างประเทศ หรือทุกๆ พื้นที่ในประเทศไทย`
