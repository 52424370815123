import React from 'react'
import axios from 'axios'
import * as R from 'ramda'
import OpdForm from './OpdForm'
import hospitalURL from '../../utils/requestURL'

const createHospitalUser = async (body) => {
  const getToken = await axios({
    method: 'post',
    url: hospitalURL('getToken'),
  })

  const token = R.pathOr(null, ['data', 'data', 'token'], getToken)
  if (!token) {
    const result = { data: { message: "can't get token" } }
    return result
  }

  const result = await axios({
    method: 'post',
    url: hospitalURL('hospitals'),
    headers: {
      'x-api-key': token,
    },
    data: body,
  })
  return result
}
const OpdFormIndex = () => <OpdForm createHospitalUser={createHospitalUser} />
export default OpdFormIndex
