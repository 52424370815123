import React, { Component } from 'react'
import uuidv4 from 'uuid/v4'
import OpdFormInput from './opdFormInput/OpdFormInput'
import SummaryInfo from './summaryInfo/SummaryInfo'
import CompletePostForm from './completePostForm/completePostForm'
import { STEP, FADE_DURATION, ERROR_MESSAGE } from './configForm'

const INITIAL_STATE = {
  step: STEP.FILL_FORM,
  isShow: true,
  loading: false,
  day: null,
  month: null,
  year: null,
  birthDateErr: null,
  phoneErr: null,
  showError: false,
  errorMessage: null,
  termsErr: null,
  completeMessage: null,
  information: {
    hn: JSON.parse(process.env.REACT_APP_HN_AUTO_GENERATE || 'false') ? `${process.env.REACT_APP_HN_PREFIX}${uuidv4()}` : '',
    firstname: '',
    lastname: '',
    email: '',
    gender: 'male',
    phone: '',
    note: '',
    birthdate: '',
    acceptTerms: false,
  },
}

class OpdForm extends Component {
  constructor(props) {
    super(props)
    this.state = INITIAL_STATE
  }

  componentDidMount() {
    document.title = process.env.REACT_APP_HOSPITAL_NAME || 'ooca'
  }

  clearState = () => {
    this.setState(INITIAL_STATE)
  };

  handleChangeCheckbox = (e) => {
    const information = {
      ...this.state.information,
      [e.target.name]: e.target.checked,
    }
    this.setState({ information })
  };

  toggleValidate = async (name, condition) => {
    let value = null
    if (!condition) {
      value = ERROR_MESSAGE[name]
    }
    this.setState({ [name]: value })
  };

  completeForm = async (e) => {
    e.preventDefault()
    let {
      day, month, year, information, termsErr,
    } = this.state

    await this.toggleValidate('birthDateErr', day && month && year)
    await this.toggleValidate('phoneErr', information.phone)
    await this.toggleValidate('termsErr', information.acceptTerms)

    const { birthDateErr, phoneErr } = this.state
    if (!birthDateErr && !phoneErr && information.acceptTerms) {
      day = String(day).padStart(2, '0')
      month = String(month).padStart(2, '0')
      const information = {
        ...this.state.information,
        birthdate: `${day}-${month}-${year}`,
      }
      this.setState({ isShow: false })
      setTimeout(() => {
        this.setState({
          step: STEP.SHOW_SUMMARY,
          information,
          isShow: true,
          birthDateErr: null,
          phoneErr: null,
        })
      }, FADE_DURATION)
    }
  }

  handleChangeInfo = (e) => {
    const information = {
      ...this.state.information,
      [e.target.name]: e.target.value,
    }
    this.setState({ information })
  };

  handlePhone = (value) => {
    this.setState({ information: { ...this.state.information, phone: value } })
  };

  handleChangeBirthDate = (e, target) => {
    this.setState({ [target.name]: target.value })
    const { month, year, day } = this.state
    const maxDayOfMount = new Date(year, month, 0).getDate()
    if (day > maxDayOfMount) {
      this.setState({
        day: maxDayOfMount,
      })
    }
  };

  handleCloseError = () => {
    this.setState({ showError: false })
  };

  moveStepTo = (step) => {
    this.setState({ isShow: false, showError: false })
    setTimeout(() => {
      this.setState({ step, isShow: true })
    }, FADE_DURATION)
  };

  prepareParamAfterSubmit = () => {
    const convertBEtoAD = process.env.REACT_APP_YEAR_TYPE === 'BE' ? -543 : 0
    let {
      information, year, month, day,
    } = this.state

    day = String(day).padStart(2, '0')
    month = String(month).padStart(2, '0')

    return {
      ...information,
      birthdate: `${year + convertBEtoAD}-${month}-${day}`,
    }
  };

  onSubmitForm = async () => {
    this.setState({ loading: true })
    const param = this.prepareParamAfterSubmit()
    try {
      const result = await this.props.createHospitalUser(param)
      this.clearState()
      this.setState({ completeMessage: result.data.message })
      this.moveStepTo(STEP.COMPLETE)
    } catch (e) {
      console.error(e.response)
      this.setState({
        errorMessage: e.response.data.message,
        showError: true,
        loading: false,
      })
    }
  };

  render() {
    const { step } = this.state
    switch (step) {
      case STEP.FILL_FORM:
        return (
          <OpdFormInput
            {...this.state}
            handlePhone={this.handlePhone}
            handleChangeInfo={this.handleChangeInfo}
            handleChangeBirthDate={this.handleChangeBirthDate}
            completeForm={this.completeForm}
            handleChangeCheckbox={this.handleChangeCheckbox}
          />
        )
      case STEP.SHOW_SUMMARY:
        return (
          <SummaryInfo
            {...this.state}
            handleChangeInfo={this.handleChangeInfo}
            onSubmitForm={this.onSubmitForm}
            moveStepTo={this.moveStepTo}
            handleCloseError={this.handleCloseError}
          />
        )
      default:
        return (
          <CompletePostForm
            completeMessage={this.state.completeMessage}
            isShow={this.state.isShow}
            moveStepTo={this.moveStepTo.bind(this)}
          />
        )
    }
  }
}

export default OpdForm
